























































































































































































































































































































































































































































































































































































































































































































































































































































































































import { debounce } from "@/helpers/debounce";
import { useAsset } from "@/hooks";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Mode } from "@/models/enums/global.enum";
import { Messages } from "@/models/enums/messages.enum";
import { DataResponseListMasterAssetNotRent } from "@/models/interface/assets.interface";
import {
  AddressDataLines,
  ContactData,
  CustomAddressDataList,
} from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { AssetResponseDto } from "@/models/interface/master-asset";
import {
  DetailInternalContract,
  EnumStatus,
  RequestInternalContract,
} from "@/models/interface/salesOrder.interface";
import { DataMasterCurrency } from "@/models/interface/settings.interface";
import { IUserRole } from "@/models/interface/user.interface";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
import { assetsServices } from "@/services/assets.service";
import { contactServices } from "@/services/contact.service";
import { logisticServices } from "@/services/logistic.service";
import { salesOrderServices } from "@/services/salesorder.service";
import { settingsServices } from "@/services/settings.service";
import {
  changeCurrencytoNumeric,
  currencyFormat,
  numericOnly,
} from "@/validator/globalvalidator";
import { Timeline } from "ant-design-vue";
import { UploadFile } from "ant-design-vue/types/upload";
import { Decimal } from "decimal.js-light";
import moment from "moment";
import Vue from "vue";
import { CustomDataSourceProductServiceTab } from "./ProductServiceTab.vue";

Vue.use(Timeline);
export interface DataRent {
  rentPeriod: number;
  startingDate: string;
}
export interface OptionBillingType {
  value: string;
  name: string;
}

export interface OptionCustom {
  name: string;
  option: any;
}

export interface DataSourceInternalContract {
  id: string;
  disabledInput?: boolean;
  disabledSelect?: boolean;
  disabledCheckBox?: boolean;
  optionCustom?: OptionCustom[];
  disableByRow?: string[];
  unitParentId: string | null;
  key?: number;
  idCategory?: string;
  endContract: boolean;
  unitCode: string;
  unitCodeBackup: boolean;
  unitCodeBackupNo: string | number;
  equipment: string;
  merk: string;
  type: string;
  qty: string | number;
  uom: string;
  rentPrice: string | number;
  subTotal: string;
  serialNumber: string;
  specification: string;
  location: string;
  shipTo: string;
}

export interface FooterCreateInternalContract {
  total: number | string;
}

export interface DetailStatus {
  status: EnumStatus; // this status can change
  isAcceptanceOfOrder: boolean;
  isSo: boolean;
  isCanCancel: boolean;
  actualStatusDocument: EnumStatus; // this status can change
}

export interface DataApprover {
  id: string;
  name: string;
}

export interface RentType {
  label: string;
  value: string;
}

export default Vue.extend({
  components: {
    ProductServiceTab: () => import("./ProductServiceTab.vue"),
  },
  data() {
    return {
      mode: "" as string,
      defaultLocationShipTo: "" as string,
      defaultShipTo: "" as string,
      listRoles: [] as IUserRole[],
      detail: {
        status: EnumStatus.New, // this status can change
        isAcceptanceOfOrder: false,
        isSo: false,
        isCanCancel: true,
        actualStatusDocument: EnumStatus.New,
      } as DetailStatus,
      idInternalContract: "" as string,
      InputNumberOnly: numericOnly,
      formatCurrencytoNumber: changeCurrencytoNumeric,
      tempColumnOption: [] as { name?: string; id?: string }[],
      modelForm: {
        date: [] as any,
      },
      dataRent: {
        rentPeriod: 0,
        startingDate: "",
      } as DataRent,
      footerCreateInternalContract: {
        total: 0,
      } as FooterCreateInternalContract,
      footerTotalProductServices: 0 as string | number,
      deleteLineId: [] as string[],
      deleteLineIdProductServices: [] as string[],
      urlForSend: "" as string,
      idBranch: "" as string,
      modalOpen: false as boolean,
      modeModal: "" as string,
      viewHistory: false as boolean,
      modalHistoryOpen: false as boolean,
      services: false as boolean,
      formatCurrency: currencyFormat,
      // dataUnitCodeDefaultTable: [] as DataListMasterAsset[],
      // dataSource: [] as DataResponseGetStockAdjustment[],
      columnsTable: [
        {
          title: "Unit Code",
          dataIndex: "unitCode",
          key: "unitCode",
          width: 200,
          scopedSlots: { customRender: "unitCode" },
          fixed: "left",
          responsiveColSelect: [
            {
              name: "unitCode",
              placeholder: "Type for more data",
              style: "width: 100%;",
              disabled: false,
              value: "id",
              options: [] as DataResponseListMasterAssetNotRent[],
              loading: false,
              filterOption: "remote",
            },
            {
              name: "unitParentId",
              placeholder: "Type for more data",
              style: "width: 100%;",
              disabled: false,
              value: "id",
              options: [] as DataResponseListMasterAssetNotRent[],
              loading: false,
            },
            {
              name: "shipTo",
              placeholder: "Type for more data",
              style: "width: 100%;",
              disabled: false,
              value: "id",
              options: [] as CustomAddressDataList[],
              loading: false,
            },
          ],
          responsiveColInput: [
            {
              name: "qty",
              placeholder: "Qty Adjustment",
              style: "width: 100%",
              disabled: false,
            },
            {
              name: "rentPrice",
              placeholder: "Rent Price",
              style: "width: 100%",
              disabled: false,
            },
          ],
          responsiveColCheckBox: [
            {
              name: "endContract",
              disabled: true,
            },
            {
              name: "unitCodeBackup",
              disabled: false,
            },
          ],
        },
        {
          title: "Unit Code Backup",
          dataIndex: "unitCodeBackup",
          key: "unitCodeBackup",
          width: 140,
          scopedSlots: { customRender: "unitCodeBackup" },
          align: "center",
        },
        {
          title: "Ship To",
          dataIndex: "shipTo",
          key: "shipTo",
          width: 200,
          scopedSlots: { customRender: "shipTo" },
        },
        {
          title: this.$t("lbl_location"),
          dataIndex: "location",
          key: "location",
          width: 200,
          scopedSlots: { customRender: "location" },
        },
        {
          title: "Parent",
          dataIndex: "unitParentId",
          key: "unitParentId",
          width: 200,
          scopedSlots: { customRender: "unitParentId" },
        },
        {
          title: "Ended",
          dataIndex: "endContract",
          key: "endContract",
          width: 200,
          scopedSlots: { customRender: "endContract" },
        },
        {
          title: "Unit Code Backup No",
          dataIndex: "unitCodeBackupNo",
          key: "unitCodeBackupNo",
          width: 160,
          align: "center",
        },
        {
          title: "Equipment",
          dataIndex: "equipment",
          key: "equipment",
          width: 150,
        },
        {
          title: "Merk",
          dataIndex: "merk",
          key: "merk",
          width: 150,
        },
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
          width: 150,
        },
        {
          title: this.$t("lbl_serial_number"),
          dataIndex: "serialNumber",
          key: "serialNumber",
          width: 150,
        },
        {
          title: "Specification",
          dataIndex: "specification",
          key: "specification",
          width: 150,
        },
        {
          title: "Qty",
          dataIndex: "qty",
          key: "qty",
          width: 150,
          scopedSlots: { customRender: "qty" },
        },
        {
          title: "UOM",
          dataIndex: "uom",
          key: "uom",
          width: 150,
          scopedSlots: { customRender: "uom" },
        },
        {
          title: "Rent Price / Month",
          dataIndex: "rentPrice",
          key: "rentPrice",
          width: 250,
          scopedSlots: { customRender: "rentPrice" },
          align: "center",
        },
        {
          title: "SUB Total",
          dataIndex: "subTotal",
          key: "subTotal",
          width: 150,
        },
        {
          title: this.$root.$t("lbl_action").toString(),
          dataIndex: "operation",
          width: "120px",
          align: "center",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
          button: ["delete"],
        },
      ] as ColumnTableCustom[],
      loading: {
        listCustomer: false,
        branchName: false,
        status: false,
        revised: false,
        table: false,
        submit: false,
        shipTo: false,
        billTo: false,
        picName: false,
        currency: false,
        billingType: false,
        sales: false,
        submitFormModal: false,
      },
      DEFAULT_DATE_FORMAT,
      attachmentFile: "" as string,
      dataRentType: [
        {
          label: "Monthly",
          value: "Month",
        },
        {
          label: "Daily",
          value: "Day",
        },
      ] as RentType[],
      rentType: "" as string,
      fileList: [] as UploadFile[],
      dataSource: [] as DataSourceInternalContract[],
      dataSourceProductServices: [] as CustomDataSourceProductServiceTab[],
      detailDataSourceProductServices:
        [] as CustomDataSourceProductServiceTab[], // this get from detail
      detailFooterTotalProductService: "" as string,
      selectedRowKeys: [] as number[],
      dataListCustomer: [] as ContactData[],
      dataListSales: [] as ContactData[],
      dataListBranch: [] as DataWarehouseBranch[],
      dataListRevised: [] as [],
      dataShipTo: [] as CustomAddressDataList[],
      dataBillTo: [] as CustomAddressDataList[],
      dataPicName: [] as AddressDataLines[],
      dataCurrency: [] as DataMasterCurrency[],
      dataApprover: [] as DataApprover[],
      dataHistoryTimeLine: [] as DetailInternalContract[],
      dataListBillingType: [
        {
          value: "Advanced",
          name: "Advanced",
        },
        {
          value: "Behind",
          name: "Behind",
        },
      ] as OptionBillingType[],
      view: false as boolean,
      form: this.$form.createForm(this, { name: "createInternalContract" }),
      formModal: this.$form.createForm(this, { name: "formModal" }),
      formRulesModal: {
        rentPeriod: {
          label: "Rent Period (Months)",
          name: "rentPeriod",
          placeholder: "Insert Rent Period",
          decorator: [
            "rentPeriod",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        notes: {
          label: "Notes",
          name: "notes",
          placeholder: "Insert Notes",
          decorator: [
            "notes",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
      formRules: {
        customerName: {
          label: "lbl_customer_name",
          name: "customerName",
          placeholder: "lbl_customer_name_placeholder",
          decorator: [
            "customerName",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        internalContractNo: {
          label: "Internal Contract No",
          name: "internalContractNo",
          placeholder: "<auto generate>",
          decorator: [
            "internalContractNo",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        internalContractDate: {
          label: "Internal Contract Date",
          name: "internalContractDate",
          placeholder: "Insert Internal Contract Date",
          decorator: [
            "internalContractDate",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        branchName: {
          label: "Branch Name",
          name: "branchName",
          placeholder: "Insert Branch Name",
          decorator: [
            "branchName",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        status: {
          label: "Status",
          name: "status",
          placeholder: "Insert Status",
          decorator: [
            "status",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        revised: {
          label: "Revised",
          name: "revised",
          placeholder: "Insert Revised",
          decorator: [
            "revised",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        referenceNo: {
          label: "Reference No",
          name: "referenceNo",
          placeholder: "Insert Reference No",
          decorator: [
            "referenceNo",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        shipTo: {
          label: "Default Ship To",
          name: "shipTo",
          placeholder: "Insert Default Ship To",
          decorator: [
            "shipTo",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        billTo: {
          label: "Bill To",
          name: "billTo",
          placeholder: "Insert Bill To",
          decorator: [
            "billTo",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        picName: {
          label: "PIC Customer",
          name: "picName",
          placeholder: "Insert PIC Customer",
          decorator: [
            "picName",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        currency: {
          label: "lbl_currency",
          name: "currency",
          placeholder: "lbl_currency_placeholder",
          decorator: [
            "currency",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        rentType: {
          label: "Rent Type",
          name: "rentType",
          placeholder: "Insert Rent Type",
          decorator: [
            "rentType",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        rentPeriod: {
          label: "Rent Period (Months)",
          name: "rentPeriod",
          placeholder: "Insert Rent Period",
          decorator: [
            "rentPeriod",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        rentStartingDate: {
          label: "Rent Starting Date",
          name: "rentStartingDate",
          placeholder: "Insert Rent Starting Date",
          decorator: [
            "rentStartingDate",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        rentPeriodRange: {
          label: "Rent Period (Range)",
          name: "rentPeriodRange",
          placeholder: "Insert Rent Period (Range)",
          decorator: [
            "rentPeriodRange",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        billingType: {
          label: "Billing Type",
          name: "billingType",
          placeholder: "Insert Billing Type",
          decorator: [
            "billingType",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        termOfCondition: {
          label: "Term Of Condition / Notes",
          name: "termOfCondition",
          placeholder: "Insert Term Of Condition",
          decorator: [
            "termOfCondition",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        fileContract: {
          label: "File Contract",
          name: "fileContract",
          placeholder: "Insert File Contract",
          decorator: [
            "fileContract",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        sales: {
          label: "Sales",
          name: "sales",
          placeholder: "Insert Sales",
          decorator: [
            "sales",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        createdBy: {
          label: "Created By",
          name: "createdBy",
          placeholder: "Insert Created By",
          decorator: [
            "createdBy",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        approver: {
          label: "Approver",
          name: "approver",
          placeholder: "Insert Approver",
          decorator: [
            "approver",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        notes: {
          label: "Notes Approve / Reject / Revised",
          name: "notes",
          placeholder: "Insert Notes",
          decorator: [
            "notes",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        services: {
          label: "lbl_services",
        },
      },
    };
  },
  watch: {
    dataSource: {
      immediate: true,
      deep: true,
      handler() {
        this.dataSource.forEach((dataForeach, index) => {
          if (dataForeach.unitParentId) {
            const findUnitCodeParent = this.tempColumnOption.find(
              dataFind => dataFind.id === dataForeach.unitParentId
            )?.id;
            const findDataByUnitCodeParent = this.dataSource.find(
              dataFind => dataFind.unitCode === findUnitCodeParent
            );

            this.dataSource[index].shipTo = findDataByUnitCodeParent
              ? findDataByUnitCodeParent.shipTo
              : "";
            this.dataSource[index].location = findDataByUnitCodeParent
              ? findDataByUnitCodeParent.location
              : "";
          }
        });
      },
    },
    mode: {
      immediate: true,
      deep: true,
      handler() {
        if (
          this.mode &&
          this.mode.toLocaleLowerCase() === Mode.CREATE &&
          this.$store.state.preferenceStore.dataPreference.length > 0
        ) {
          this.setBaseCurrencyCreate();
        }
      },
    },
    defaultLocationShipTo: {
      immediate: true,
      deep: true,
      handler() {
        this.dataSource = this.dataSource.map(dataMap => {
          if (!dataMap.unitParentId && !dataMap.shipTo) {
            return dataMap;
          } else {
            return { ...dataMap, location: this.defaultLocationShipTo };
          }
        });
      },
    },
    defaultShipTo: {
      immediate: true,
      deep: true,
      handler() {
        this.dataSource = this.dataSource.map(dataMap => {
          if (!dataMap.unitParentId && !dataMap.shipTo) {
            return dataMap;
          } else {
            return { ...dataMap, shipTo: this.defaultShipTo };
          }
        });
      },
    },
    tempColumnOption: {
      immediate: true,
      deep: true,
      handler() {
        if (this.tempColumnOption) {
          this.dataSource = this.dataSource.map(dataMap => {
            return {
              ...dataMap,
              optionCustom: [
                {
                  name: "unitParentId",
                  option: this.tempColumnOption,
                },
              ],
            };
          });
        }
      },
    },
    "$store.state.preferenceStore.dataPreference": {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (
          newValue &&
          newValue.length > 0 &&
          this.mode &&
          this.mode.toLocaleLowerCase() === Mode.CREATE
        ) {
          this.setBaseCurrencyCreate();
        }
      },
    },
    "$store.state.authStore.authData": {
      immediate: true,
      deep: true,
      handler() {
        if (
          this.$store.state.authStore.authData &&
          this.$store.state.authStore.authData.roles
        ) {
          this.listRoles = this.$store.state.authStore.authData.roles;
        }
      },
    },
    rentType: {
      immediate: true,
      deep: true,
      handler() {
        switch (this.rentType) {
          case "Month":
            this.formRules.rentPeriod.label = "Rent Period (Months)";
            this.formRulesModal.rentPeriod.label = "Rent Period (Months)";
            this.columnsTable[14].title = "Rent Price / Month";
            break;
          case "Day":
            this.formRules.rentPeriod.label = "Rent Period (Day)";
            this.formRulesModal.rentPeriod.label = "Rent Period (Day)";
            this.columnsTable[14].title = "Rent Price / Day";
            break;
        }
        this.columnsTable = this.columnsTable.slice();
      },
    },
    detail: {
      immediate: true,
      deep: true,
      handler() {
        if (this.isRevised || this.isNew || this.isDraft) {
          this.view = false;
          this.dataSource = this.dataSource.map(dataMap => {
            return {
              ...dataMap,
              disabledInput: false,
              disabledSelect: false,
              disabledCheckBox: false,
            };
          });
        } else if (
          this.isNeedApproval ||
          this.isEnded ||
          this.isRejected ||
          this.isApproved ||
          this.isPartialEnded
        ) {
          this.view = true;
          this.dataSource = this.dataSource.map(dataMap => {
            return {
              ...dataMap,
              disabledInput: true,
              disabledSelect: true,
              disabledCheckBox: true,
            };
          });
        }
      },
    },
    dataRent: {
      immediate: true,
      deep: true,
      handler(value) {
        if (
          this.rentType === "Month" &&
          value.startingDate &&
          value.rentPeriod
        ) {
          const futureMonth = moment(value.startingDate, "DD-MMM-yyyy")
            .add(value.rentPeriod, "M")
            .subtract(1, "days");
          this.form.setFieldsValue({
            rentPeriodRange: `${value.startingDate} - ${moment(
              futureMonth
            ).format("DD-MMM-yyyy")}`,
          });
        } else if (this.rentType === "Day" && this.dataRent.startingDate) {
          const futureMonth = moment(
            this.dataRent.startingDate,
            "DD-MMM-yyyy"
          ).add(this.dataRent.rentPeriod, "d");
          this.form.setFieldsValue({
            rentPeriodRange: `${this.dataRent.startingDate} - ${moment(
              futureMonth
            ).format("DD-MMM-yyyy")}`,
          });
        } else {
          this.form.resetFields(["rentPeriodRange"]);
        }
      },
    },
  },
  methods: {
    moment,
    debounce,
    setDataForm(values): void {
      for (const key in values) {
        this.form.getFieldDecorator(key, {
          initialValue: values[key],
        });
      }
      this.form.setFieldsValue(values);
    },
    setDeleteProductServices(value) {
      this.deleteLineIdProductServices = value;
    },
    setTotalProductServices(value) {
      this.footerTotalProductServices = value;
    },
    setDataSourceProductServices(value: CustomDataSourceProductServiceTab[]) {
      this.dataSourceProductServices = value;
    },
    setBaseCurrencyCreate() {
      const tempObject = {
        feature_base_currency: "",
      };
      this.$store.state.preferenceStore.dataPreference.forEach(dataForeach => {
        if (dataForeach.key === "feature_base_currency") {
          tempObject.feature_base_currency = dataForeach.value;
        }
      });
      const params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
      };
      if (tempObject.feature_base_currency) {
        params.search = `secureId~${tempObject.feature_base_currency}`;
        settingsServices.listOfMasterCurrency(params, "").then(response => {
          this.setDataForm({
            currency: response.data[0].id,
          });
        });
      }
    },
    handleChangeRentType(value) {
      this.rentType = value;
      switch (value) {
        case "Month":
          this.formRules.rentPeriod.label = "Rent Period (Months)";
          this.columnsTable[14].title = "Rent Price / Month";
          break;
        case "Day":
          this.formRules.rentPeriod.label = "Rent Period (Day)";
          this.columnsTable[14].title = "Rent Price / Day";
          break;
      }
      this.dataRent.rentPeriod = 0;
      this.form.resetFields(["rentPeriod", "rentPeriodRange"]);
      this.columnsTable = this.columnsTable.slice();
    },
    onBlur(value, form: string) {
      if (value.target.value && !this.form.getFieldError(`${form}`)) {
        let dataobj = {} as { [props: string]: string };
        dataobj[form] = this.formatCurrency(value.target.value);
        this.form.setFieldsValue(dataobj);
      }
    },
    onFocus(value, form: string) {
      if (value.target.value && !this.form.getFieldError(`${form}`)) {
        let dataobj = {} as { [props: string]: number };
        dataobj[form] = this.formatCurrencytoNumber(value.target.value);
        this.form.setFieldsValue(dataobj);
      }
    },
    getCheckboxProps: record => ({
      props: {
        disabled: record.endContract ? true : false, // Column configuration not to be checked
        name: "endContract",
      },
    }),
    onSelectChange(selectedRowKeys: number[]) {
      this.selectedRowKeys = selectedRowKeys;
    },
    handleViewHistory(indexHistory) {
      this.viewHistory = true;
      this.dataHistoryTimeLine.forEach((dataForeach, index) => {
        if (indexHistory === index) {
          this.detail.status = dataForeach.status;
          this.detail.actualStatusDocument = dataForeach.status;
          // check duplicate
          this.checkDuplicateDataBranch({
            name: dataForeach.branchName,
            id: "",
          });
          this.assignCustomer(dataForeach);
          this.checkDuplicateDataCurrencyId({
            code: dataForeach.currencyCode,
            id: "",
          });
          this.checkDuplicateDataSales({ name: dataForeach.salesName, id: "" });
          // assign and setfieldsvalue
          this.assignShipTo({ address: dataForeach.shipTo });
          this.assignBillTo({ address: dataForeach.billTo });
          this.assignPicName({ picName: dataForeach.picName });
          // this.assignBillingType({ billingType: dataForeach.billingType }) // karena option hardcode dri FE maka di comment
          this.assignApprover({
            name: dataForeach.approverName,
            id: dataForeach.approverId,
          });

          if (dataForeach.fileContract) {
            const splited = dataForeach.fileContract.split("/");
            this.fileList = [
              {
                url: dataForeach.fileContract,
                uid: "1",
                size: 0,
                name: splited[splited.length - 1],
                type: "",
              },
            ];
          }
          this.dataRent.rentPeriod = dataForeach.rentPeriod;
          this.dataRent.startingDate = moment(dataForeach.startRent).format(
            "DD-MMM-yyyy"
          );
          this.footerCreateInternalContract.total = currencyFormat(
            dataForeach.totalRentPrice
          );
          this.urlForSend = dataForeach.fileContract;

          this.setDataForm({
            internalContractNo: dataForeach.documentNo,
            branchName: dataForeach.branchName,
            referenceNo: dataForeach.referenceNo,
            internalContractDate: moment(dataForeach.contractDate),
            rentPeriod: dataForeach.rentPeriod,
            rentStartingDate: moment(dataForeach.startRent),
            rentPeriodRange: dataForeach.rentPeriodRange,
            termOfCondition: dataForeach.termOfCondition,
            revised: dataForeach.revised,
            createdBy: dataForeach.createdBy,
            notes: dataForeach.notes,
            customerName: dataForeach.customerName,
            shipTo: dataForeach.shipTo,
            billTo: dataForeach.billTo,
            picName: dataForeach.picName,
            currency: dataForeach.currencyCode,
            billingType: dataForeach.billingType,
            sales: dataForeach.salesName,
            status: dataForeach.status,
            approver: dataForeach.approverId,
          });

          this.dataSource = dataForeach.internalContractDetailList.map(
            dataMap => {
              const findColumn = this.columnsTable.find(
                column => column.responsiveColSelect
              )?.responsiveColSelect;
              if (findColumn) {
                const checkData = findColumn[0].options.some(
                  dataSome => dataSome.name === dataMap.unitCode
                );
                if (!checkData)
                  this.assignColumnOption(
                    0,
                    dataMap.unitCode,
                    dataMap.unitCode,
                    dataMap.unitCodeIsParent
                  );
              }
              return {
                id: dataMap.id,
                uom: dataMap.uomUnit,
                rentPrice: currencyFormat(dataMap.rentPrice),
                subTotal: currencyFormat(dataMap.subTotal),
                unitCode: dataMap.unitCode,
                unitCodeBackup: dataMap.unitBackup,
                unitCodeBackupNo: dataMap.unitBackupNumber,
                unitParentId: dataMap.unitParentId,
                equipment: dataMap.equipment,
                merk: dataMap.merk,
                type: dataMap.type,
                qty: currencyFormat(dataMap.qty),
                key: index,
                endContract: dataMap.endContract,
                idCategory: dataMap.equipment,
                specification: dataMap.specification,
                shipTo: dataMap.shipTo,
                location: dataMap.location as string,
                serialNumber: dataMap.serialNumber as string,
                disabledSelect: this.isActualNew ? false : true,
                disabledInput: this.isActualNew ? false : true,
              };
            }
          );
          this.modalHistoryOpen = false;
        }
      });
    },
    viewOriginalModalHistory(e: Event) {
      e.preventDefault();
      this.getDataDetail();
      this.viewHistory = false;
      this.modalHistoryOpen = false;
    },
    handleCancelModalHistory() {
      this.modalHistoryOpen = false;
    },
    handleCancelModal() {
      this.modalOpen = false;
      this.modeModal = "";
    },
    handleChangeBranch(id) {
      this.idBranch = id;
      this.getUnitCode("");
    },
    mappingSubTotal(dataMap) {
      return {
        ...dataMap,
        subTotal: currencyFormat(
          new Decimal(changeCurrencytoNumeric(dataMap.qty) || 0)
            .times(new Decimal(changeCurrencytoNumeric(dataMap.rentPrice) || 0))
            .times(this.dataRent.rentPeriod || 0)
        ),
      };
    },
    handleCountingSubTotalListIc() {
      this.dataSource = this.dataSource.map(dataMap => {
        return this.mappingSubTotal(dataMap);
      });
      const sumSubTotal = this.dataSource.reduce((current, dataReduce) => {
        return new Decimal(changeCurrencytoNumeric(dataReduce.subTotal))
          .plus(current)
          .toNumber();
      }, 0);
      this.footerCreateInternalContract.total = currencyFormat(
        sumSubTotal || 0
      );
    },
    handleCountingSubTotalProductService() {
      this.dataSourceProductServices = this.dataSourceProductServices.map(
        dataMap => {
          return this.mappingSubTotal(dataMap);
        }
      );
      this.detailDataSourceProductServices =
        this.detailDataSourceProductServices.map(dataMap => {
          return this.mappingSubTotal(dataMap);
        });

      const dataProductService =
        this.dataSourceProductServices.length > 0
          ? this.dataSourceProductServices
          : this.detailDataSourceProductServices;

      const sumSubTotalProductService = dataProductService.reduce(
        (current, dataReduce) => {
          return new Decimal(changeCurrencytoNumeric(dataReduce.subTotal))
            .plus(current)
            .toNumber();
        },
        0
      );
      this.footerTotalProductServices = currencyFormat(
        sumSubTotalProductService || 0
      );
    },
    handleChangeRentPeriod(months: number, isModal?: boolean) {
      if (isModal) {
        const value = this.form.getFieldsValue(["rentPeriod"]) as any;
        this.dataRent.rentPeriod = value.rentPeriod + months;
      } else {
        this.dataRent.rentPeriod = months;
      }
      this.handleCountingSubTotalListIc();
      this.handleCountingSubTotalProductService();
    },
    handleChangeRentStartingDate(date) {
      if (date) {
        this.dataRent.startingDate = moment(date).format("DD-MMM-yyyy");
      } else {
        this.dataRent.startingDate = "";
      }
    },
    handleChangeShipTo(address) {
      this.form.resetFields(["picName"]);
      const dataFind = this.dataShipTo.find(
        dataAddress => dataAddress.address === address
      )?.location;
      this.defaultLocationShipTo = dataFind ? dataFind : "";
      this.defaultShipTo = address;
      // this.dataPicName = this.dataShipTo.filter((dataAddress) => dataAddress.address === address)
      const findShipto = this.dataShipTo.find(
        dataAddress => dataAddress.address === address
      );
      this.dataPicName =
        findShipto && findShipto.addressDataLines
          ? findShipto.addressDataLines
          : [];
    },
    handleChangeCustomer(id) {
      this.form.resetFields(["shipTo", "billTo", "picName"]);
      this.getDetailContactData(id);
    },
    uploadFile(options) {
      const formData = new FormData();
      formData.append("file", options.file);
      // upload file
      assetsServices
        .createUploadFile(formData, "sales")
        .then(response => {
          options.onSuccess(options);
          this.urlForSend = response.url;
        })
        .catch(err => {
          options.onError(err, options);
        });
    },
    beforeUpload(file) {
      const isLt5M = file.size;
      if (isLt5M >= 5242880) {
        this.$notification.error({
          description: "File must smaller than 5MB!",
          message: "Error",
          duration: 30,
        });
      }
      return isLt5M;
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    handleChangeFileContract(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });
      this.fileList = fileList;
      if (info.file.status !== "uploading") {
        this.uploadProses = true;
      }
      if (info.file.status === "done") {
        this.fileList = info.fileList;
        this.fileList[0] = { ...this.fileList[0], url: this.attachmentFile };
        this.uploadProses = false;
        // this.attachmentFile = info.file.response.url
        this.$notification.success({
          description: `${info.file.name} file uploaded successfully`,
          message: "Success",
          duration: 30,
        });
      } else if (info.file.status === "error") {
        this.uploadProses = true;
        this.$notification.error({
          description: `${info.file.name} file upload failed.`,
          message: "Error",
          duration: 30,
        });
      }
    },
    responseDeleteTable(response) {
      if (this.isRevised || this.isNew || this.isDraft) {
        this.dataSource = this.dataSource.filter((data, index) => {
          if (response.data.key === index && data.id)
            this.deleteLineId.push(data.id);
          return index !== response.data.key;
        });
        this.dataSource.forEach((data, index) => (data.key = index));
        this.dataSource = this.dataSource.slice();
        this.handleCountingSubTotalListIc();
        // delete parent in option while row unit code (parent) is deleted
        this.tempColumnOption = this.tempColumnOption.filter(dataMap => {
          if (
            this.dataSource.some(dataSome => dataSome.unitCode === dataMap.name)
          )
            return dataMap;
          else return;
        });
      } else {
        this.$notification.error({
          message: "Error",
          description: "You can't delete data",
        });
      }
    },
    getListCustomer(valueSearch) {
      const params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
        search: "customer~true_AND_active~true",
      };
      if (valueSearch)
        params.search += `_AND_firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*`;
      this.loading.listCustomer = true;
      contactServices
        .listContactData(params)
        .then(response => (this.dataListCustomer = response.data))
        .finally(() => (this.loading.listCustomer = false));
    },
    getCurrency(valueSearch) {
      const params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
      };
      if (valueSearch) params.search = `currencyCode~${valueSearch}`;
      this.loading.currency = true;
      settingsServices
        .listOfMasterCurrency(params, "")
        .then(response => (this.dataCurrency = response.data))
        .finally(() => (this.loading.currency = false));
    },
    getListBillingType(valueSearch) {
      let params: RequestQueryParamsModel = {};
      if (valueSearch) params.search = `abcd`;
      this.loading.billingType = true;
      this.loading.billingType = false;
    },
    getListSales(valueSearch) {
      const params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
        search: "employee~true_AND_active~true_AND_employeeData.position~Sales",
      };
      if (valueSearch)
        params.search += `_AND_firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*`;
      this.loading.sales = true;
      contactServices
        .listContactData(params)
        .then(response => (this.dataListSales = response.data))
        .finally(() => (this.loading.sales = false));
    },
    getListBranch(valueSearch) {
      const params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
      };
      if (valueSearch)
        params.search = `name~*${valueSearch}*_OR_address~*${valueSearch}*`;
      this.loading.branchName = true;
      logisticServices
        .listWarehouseBranch(params, "")
        .then(response => (this.dataListBranch = response.data))
        .finally(() => (this.loading.branchName = false));
    },
    getListStatus(valueSearch) {
      let params: RequestQueryParamsModel = {};
      if (valueSearch) params.search = `abcd`;
      this.loading.status = true;
      this.loading.status = false;
    },
    getListRevised(valueSearch) {
      let params: RequestQueryParamsModel = {};
      if (valueSearch) params.search = `abcd`;
      this.loading.revised = true;
      this.loading.revised = false;
    },
    // Detail
    // check Duplicate Data
    checkDuplicateDataBranch({ name, id }) {
      let check = false;
      this.dataListBranch.forEach(dataForeach => {
        if (dataForeach.id === id) {
          check = true;
        }
      });
      if (!check) {
        this.dataListBranch = [
          ...this.dataListBranch,
          {
            id,
            name,
            code: "",
            createdDate: "",
            modifiedDate: "",
            address: "",
          },
        ];
      }
    },
    checkDuplicateDataCurrencyId({ code, id }) {
      let check = false;
      this.dataCurrency.forEach(dataForeach => {
        if (dataForeach.id === id) {
          check = true;
        }
      });
      if (!check) {
        this.dataCurrency = [
          ...this.dataCurrency,
          {
            id,
            currencyCode: code,
            description: code,
          },
        ];
      }
    },
    checkDuplicateDataSales({ name, id }) {
      let check = false;
      this.dataListSales.forEach(dataForeach => {
        if (dataForeach.id === id) {
          check = true;
        }
      });
      if (!check) {
        this.dataListSales = [
          ...this.dataListSales,
          {
            id,
            fullName: name,
            employeeNumber: "",
            customerNumber: "",
            supplierNumber: "",
            phoneNumber: "",
            email: "",
            active: true,
          },
        ];
      }
    },
    // Just Assign Data
    assignShipTo({ address }) {
      this.dataShipTo = [
        {
          address,
          country: "",
          cityDistrict: "",
          postalCode: "",
          picName: "",
          picContactNumber: "",
          idCardNumber: "",
          shipTo: true,
          billTo: false,
          primaryShipTo: false,
          primaryBillTo: false,
        },
      ];
    },
    assignBillTo({ address }) {
      this.dataBillTo = [
        {
          address,
          country: "",
          cityDistrict: "",
          postalCode: "",
          picName: "",
          picContactNumber: "",
          idCardNumber: "",
          shipTo: false,
          billTo: true,
          primaryShipTo: false,
          primaryBillTo: false,
        },
      ];
    },
    assignPicName({ picName }) {
      this.dataPicName = [
        {
          picName,
          telephoneNo: "",
          phoneNo: "",
          email: "",
          position: "",
          key: 0,
        },
      ];
    },
    assignBillingType({ billingType }) {
      this.form.setFieldsValue({ billingType });
    },
    assignApprover({ name, id }) {
      if (name && id) {
        this.dataApprover = [
          {
            name,
            id,
          },
        ];
      }
    },

    assignColumnOption(
      key: number,
      name,
      id,
      unitCodeIsParent?,
      location = ""
    ) {
      const findColumn = this.columnsTable.find(
        column => column.responsiveColSelect
      )?.responsiveColSelect;
      if (findColumn) {
        findColumn[key].options.push({
          serialNumber: "",
          unitCode: "",
          parentUnitCode: unitCodeIsParent === false ? "parentUnitCode" : "",
          assetLocation: {
            createdDate: "",
            modifiedDate: "",
            id: "",
            name: "",
            warehouse: {
              name: "",
              id: "",
              modifiedDate: "",
              createdDate: "",
              branchWarehouse: {
                createdDate: "",
                modifiedDate: "",
                id: "",
                name: "",
                address: "",
                code: "",
              },
            },
          },
          status: "",
          invoiceNumber: "",
          purchaseOrderNumber: "",
          supplier: "",
          customerName: "",
          customerLocation: "",
          employeeName: "",
          warranty: "",
          model: "",
          originalCost: 0,
          currentCostAsset: "",
          leasingNumber: "",
          leasingValue: 0,
          leasorName: "",
          leasingPeriod: 0,
          totalLeasingCost: 0,
          lifeMonths: 0,
          currentLifeMonths: 0,
          acquisitionDate: "",
          leasing: false,
          depreciate: false,
          type: "",
          capacity: "",
          qrCode: "",
          salvageValue: 0,
          fileAttachment: "",
          id: id,
          // code: '',
          name: name,
          createdDate: "",
          modifiedDate: "",
          assetNo: 0,
          assetCategory: { id: "", segments: [] },
          description: "",
          assetBookId: "",
          customerId: "",
          customerFirstName: "",
          leasingPercent: 0,
          leasingTax: 0,
          unit: "" as never,
          country: "",
          cityDistrict: "",
          postalCode: "",
          address: "",
          picName: "",
          picContactNumber: "",
          idCardNumber: "",
          shipTo: false,
          billTo: false,
          primaryShipTo: false,
          primaryBillTo: false,
          location: location,
          key: findColumn[key].options.length,
        });
      }
    },
    getDetailContactData(customerId, selectedAddress?) {
      contactServices.getContactData(customerId).then(responseContactData => {
        this.dataShipTo = responseContactData.addressDataList.filter(
          dataAddress => dataAddress.shipTo
        );
        this.dataBillTo = responseContactData.addressDataList.filter(
          dataAddress => dataAddress.billTo
        );
        this.form.setFieldsValue({
          billTo: this.dataBillTo[0]?.address,
        });
        const findColumn = this.columnsTable.find(
          column => column.responsiveColSelect
        )?.responsiveColSelect;
        if (findColumn) {
          findColumn[2].options = this.dataShipTo.map(dataMap => {
            return { ...dataMap, name: dataMap.address, id: dataMap.address };
          });
        }
        if (selectedAddress) {
          const dataFind = this.dataShipTo.find(
            dataAddress => dataAddress.address === selectedAddress
          )?.location;
          this.defaultLocationShipTo = dataFind ? dataFind : "";
        }
      });
    },
    filterDuplicateWithSameId(dataArray) {
      return dataArray.filter(
        (value, index, self) => index === self.findIndex(t => t.id === value.id)
      );
    },
    assignCustomer(response: DetailInternalContract) {
      const partial = (
        dataListCustomer: ContactData[],
        fieldsUpdate: Partial<ContactData>
      ) => {
        return [...dataListCustomer, fieldsUpdate] as ContactData[];
      };
      this.dataListCustomer = this.filterDuplicateWithSameId(
        partial(this.dataListCustomer, {
          id: response.customerId,
          fullName: response.customerName,
        })
      );
    },
    getDataDetail() {
      salesOrderServices
        .getDetailInternalContract(this.idInternalContract)
        .then(async response => {
          this.dataResponseIc = response;
          this.services = response.service;
          this.detail.status = response.status;
          this.detail.actualStatusDocument = response.status;
          this.detail.isAcceptanceOfOrder = response.isAcceptanceOfOrder;
          this.detail.isSo = response.isSo;
          this.detail.isCanCancel = response.isCanCancel;
          // check duplicate
          this.assignCustomer(response);
          this.checkDuplicateDataBranch({
            name: response.branchName,
            id: response.branchId,
          });
          this.checkDuplicateDataCurrencyId({
            code: response.currencyCode,
            id: response.currencyId,
          });
          this.checkDuplicateDataSales({
            name: response.salesName,
            id: response.salesId,
          });
          // assign and setfieldsvalue
          this.getDetailContactData(response.customerId, response.shipTo);
          this.assignPicName({ picName: response.picName });
          // this.assignBillingType({ billingType: response.billingType }) // karena option hardcode dri FE maka di comment
          this.assignApprover({
            name: response.approverName,
            id: response.approverId,
          });
          // get list unitCode
          this.idBranch = response.branchId;
          const responseUnitCode = await this.getUnitCodeTableDetail();
          const findColumn = this.columnsTable.find(
            column => column.responsiveColSelect
          )?.responsiveColSelect;
          if (findColumn)
            findColumn[0].options = responseUnitCode.map(dataMap => {
              return { ...dataMap, name: dataMap.unitCode };
            });
          this.dataRent.rentPeriod = response.rentPeriod;
          this.dataRent.startingDate = moment(response.startRent).format(
            "DD-MMM-yyyy"
          );
          this.urlForSend = response.fileContract;

          if (this.isEnded || this.isRejected || this.isCancelled) {
            this.viewHistory = true;
          }
          if (response.fileContract) {
            const splited = response.fileContract.split("/");
            this.fileList = [
              {
                url: response.fileContract,
                uid: "1",
                size: 0,
                name: splited[splited.length - 1],
                type: "",
              },
            ];
          }

          this.rentType = response.periodType;
          this.idBranch = response.branchId;

          this.footerCreateInternalContract.total = currencyFormat(
            response.totalRentPrice
          );
          this.setDataForm({
            rentType: response.periodType,
            internalContractNo: response.documentNo,
            branchName: response.branchId,
            referenceNo: response.referenceNo,
            internalContractDate: moment(response.contractDate),
            rentPeriod: response.rentPeriod,
            rentStartingDate: moment(response.startRent),
            rentPeriodRange: response.rentPeriodRange,
            termOfCondition: response.termOfCondition,
            revised: response.revised,
            createdBy: response.createdBy,
            notes: response.notes,
            customerName: response.customerId,
            shipTo: response.shipTo,
            billTo: response.billTo,
            picName: response.picName,
            currency: response.currencyId,
            billingType: response.billingType,
            sales: response.salesId,
            status: response.status,
            approver: response.approverId,
          });

          // product services
          this.detailFooterTotalProductService =
            response.totalPriceProductService
              ? currencyFormat(response.totalPriceProductService)
              : "";

          this.detailDataSourceProductServices = response.productServices.map(
            (dataMap, index) => {
              return {
                key: index,
                productId: dataMap.productId,
                productName: dataMap.productName,
                productCode: dataMap.productCode,
                qty: currencyFormat(dataMap.qty),
                uom: dataMap.uomUnit,
                rentPrice: currencyFormat(dataMap.rentPrice),
                subTotal: currencyFormat(dataMap.subTotal),
                id: dataMap.id,
                type: dataMap.type,
                disabledSelect: this.isActualNew ? false : true,
                disabledInput: this.isActualNew ? false : true,
                optionCustom: [
                  {
                    name: "productCode",
                    option: [
                      {
                        customCode: dataMap.productCode,
                        customName: dataMap.productName,
                        id: dataMap.productId,
                        name: dataMap.productCode,
                      },
                    ],
                  },
                  {
                    name: "productName",
                    option: [
                      {
                        customCode: dataMap.productCode,
                        customName: dataMap.productName,
                        id: dataMap.productId,
                        name: dataMap.productName,
                      },
                    ],
                  },
                ],
              };
            }
          );

          this.dataSourceProductServices = response.productServices.map(
            (dataMap, index) => {
              return {
                key: index,
                productId: dataMap.productId,
                productName: dataMap.productName,
                productCode: dataMap.productCode,
                qty: currencyFormat(dataMap.qty),
                uom: dataMap.uomUnit,
                rentPrice: currencyFormat(dataMap.rentPrice),
                subTotal: currencyFormat(dataMap.subTotal),
                id: dataMap.id,
                type: dataMap.type,
                disabledSelect: this.isActualNew ? false : true,
                disabledInput: this.isActualNew ? false : true,
                optionCustom: [
                  {
                    name: "productCode",
                    option: [
                      {
                        customCode: dataMap.productCode,
                        customName: dataMap.productName,
                        id: dataMap.productId,
                        name: dataMap.productCode,
                      },
                    ],
                  },
                  {
                    name: "productName",
                    option: [
                      {
                        customCode: dataMap.productCode,
                        customName: dataMap.productName,
                        id: dataMap.productId,
                        name: dataMap.productName,
                      },
                    ],
                  },
                ],
              };
            }
          );

          this.dataSource = response.internalContractDetailList.map(
            (dataMap, index) => {
              if (findColumn) {
                const checkData = findColumn[0].options.some(
                  dataSome => dataSome.name === dataMap.unitCode
                );
                if (!checkData)
                  this.assignColumnOption(
                    0,
                    dataMap.unitCode,
                    dataMap.unitId,
                    dataMap.unitCodeIsParent
                  );
              }
              if (dataMap.unitCodeParent) {
                const checkData = this.tempColumnOption.some(
                  dataSome => dataSome.name === dataMap.unitCode
                );
                if (!checkData)
                  this.tempColumnOption.push({
                    name: dataMap.unitCodeParent,
                    id: dataMap.unitParentId,
                  });
              }
              return {
                disableByRow: dataMap.unitCodeIsParent ? ["unitParentId"] : [],
                uom: dataMap.uomUnit,
                rentPrice: currencyFormat(dataMap.rentPrice),
                subTotal: currencyFormat(dataMap.subTotal),
                unitCode: dataMap.unitId,
                unitCodeBackup: dataMap.unitBackup,
                unitCodeBackupNo: dataMap.unitBackupNumber,
                unitParentId: dataMap.unitParentId,
                equipment: dataMap.equipment,
                merk: dataMap.merk,
                type: dataMap.type,
                qty: currencyFormat(dataMap.qty),
                key: index,
                endContract: dataMap.endContract,
                idCategory: dataMap.equipment,
                id: dataMap.id,
                shipTo: dataMap.shipTo,
                serialNumber: dataMap.serialNumber as string,
                specification: dataMap.specification,
                location: dataMap.location as string,
                disabledSelect: this.isActualNew ? false : true,
                disabledInput: this.isActualNew ? false : true,
              };
            }
          );
        });
    },

    // Method Handle Table
    handleCheckBoxTable(
      checked: boolean,
      key: any,
      nameColCheckbox: any,
      _value: any
    ): void {
      if (checked) {
        this.dataSource[key].subTotal = String(
          Number(this.dataSource[key].qty) *
            Number(this.dataSource[key].rentPrice)
        );
        if (!isNaN(Number(this.dataSource[key].subTotal))) {
          this.dataSource[key].subTotal = currencyFormat(
            this.dataSource[key].subTotal
          );
        }
        this.handleCountingSubTotalListIc();
        this.dataSource[key].qty = currencyFormat(this.dataSource[key].qty);
        this.handleCountingSubTotalListIc();
      }
      this.dataSource[key][nameColCheckbox] = checked;
      this.dataSource = this.dataSource.slice();
    },
    handleInput(value, key, objectColInput, objectColName, onEvent): void {
      if (onEvent === "onFocus") {
        if (objectColName === "qty") {
          this.dataSource[key].qty = changeCurrencytoNumeric(value);
          this.dataSource[key].rentPrice = changeCurrencytoNumeric(
            this.dataSource[key].rentPrice
          );
        } else if (objectColName === "rentPrice") {
          this.dataSource[key].rentPrice = changeCurrencytoNumeric(value);
          this.dataSource[key].qty = changeCurrencytoNumeric(
            this.dataSource[key].qty
          );
        }
      }

      if (onEvent === "onChange") {
        if (objectColName === "qty") {
          const includePalletKayuOrPlastik =
            this.dataSource[key].idCategory
              ?.toLocaleLowerCase()
              .includes("pallet kayu") ||
            this.dataSource[key].idCategory
              ?.toLocaleLowerCase()
              .includes("pallet plastik")
              ? true
              : false;
          // jika category mengandung pallet kayu atau pallet plastik bisa edit qty
          if (
            this.dataSource[key].idCategory &&
            this.dataSource[key].idCategory !== undefined &&
            includePalletKayuOrPlastik
          ) {
            this.dataSource[key][objectColInput.name] = value;
            this.dataSource = this.dataSource.slice();
          }
        } else {
          this.dataSource[key][objectColInput.name] = value;
          this.dataSource = this.dataSource.slice();
        }
      }
      if (onEvent === "onBlur") {
        this.dataSource[key].subTotal = currencyFormat(
          new Decimal(this.dataSource[key].qty || 0)
            .times(new Decimal(this.dataSource[key].rentPrice || 0))
            .times(this.dataRent.rentPeriod || 0)
        );
        const sumSubTotal = this.dataSource.reduce((current, dataReduce) => {
          return new Decimal(changeCurrencytoNumeric(dataReduce.subTotal))
            .plus(current)
            .toNumber();
        }, 0);
        this.footerCreateInternalContract.total = currencyFormat(
          sumSubTotal || 0
        );
        this.dataSource[key].qty = currencyFormat(this.dataSource[key].qty);
        this.dataSource[key].rentPrice = currencyFormat(
          this.dataSource[key].rentPrice
        );
      }
    },
    handleSelect(value, key, col) {
      this.dataSource[key] = { ...this.dataSource[key], [col]: value };
      this.dataSource = this.dataSource.slice();
      if (col === "unitCode") {
        const paramsParent: RequestQueryParamsModel = {};

        const findColumn = this.columnsTable.find(
          column => column.responsiveColSelect
        )?.responsiveColSelect;
        if (findColumn) {
          const dataFind = findColumn[0].options.find(
            findData => findData.id === value
          );
          paramsParent.search = `parentUnitCode~${dataFind?.unitCode}`;

          if (!dataFind?.parentUnitCode) {
            // jika parent
            this.dataSource[key].disableByRow = ["unitParentId"];
            const checkData = this.tempColumnOption.some(
              dataSome => dataSome.name === dataFind?.name
            );
            if (!checkData)
              this.tempColumnOption.push({
                name: dataFind?.unitCode,
                id: dataFind?.id,
              });
            this.tempColumnOption = this.tempColumnOption.filter(dataFilter =>
              this.dataSource.some(
                dataSome => dataSome.unitCode === dataFilter.id
              )
            );
          } else {
            // jika child
            this.dataSource[key].shipTo = "";
            this.dataSource[key].location = "";
            this.dataSource[key].disableByRow = ["shipTo"];
            this.tempColumnOption = this.tempColumnOption.filter(dataFilter =>
              this.dataSource.some(
                dataSome => dataSome.unitCode === dataFilter.id
              )
            );
          }

          this.dataSource[key].equipment = dataFind?.assetCategory.segments[0]
            .first as string;
          this.dataSource[key].merk = dataFind?.assetCategory.segments[1]
            .first as string;
          this.dataSource[key].type = dataFind?.type as string;
          this.dataSource[key].serialNumber = dataFind?.serialNumber as string;
          this.dataSource[key].specification = dataFind?.description as string;
          this.dataSource[key].uom = dataFind?.uomName || ("" as string);
          this.dataSource[key].idCategory = dataFind?.assetCategory.id;
          this.dataSource[key].qty = "1";
          this.dataSource[key].unitParentId = null;
          this.handleInput("1", key, { name: "qty" }, "qty", "onFocus");
          this.handleInput("1", key, { name: "qty" }, "qty", "onChange");
          this.handleInput("1", key, { name: "qty" }, "qty", "onBlur");
          this.columnsTable = this.columnsTable.slice();
          this.dataSource = this.dataSource.slice();
        }
      }
      if (col === "shipTo") {
        const findShipTo = this.dataShipTo.find(
          dataFind => dataFind.address === value
        );
        this.dataSource[key].location = findShipTo
          ? (findShipTo.location as string)
          : "";
      }
    },
    handleSearchSelectTable(value, _key, colName) {
      switch (colName) {
        case "unitCode":
          debounce(() => this.getUnitCode(value), 400);
          break;
        case "equipment":
          // this.getListProduct(value)
          break;
        case "merk":
          // this.getListOfBaseUnit(value)
          break;
        case "type":
          // this.getListOfBaseUnit(value)
          break;
        // tinggal kasih case klo ada column baru
        default:
          break;
      }
    },

    // GET TABLE
    getUnitCode(valueSearch: string) {
      const { findAllAssetRent } = useAsset();
      const INCLUDE_STATUS = "RENTED,READY,NEED_TO_REPAIR";
      if (this.idBranch) {
        const params: RequestQueryParamsModel = {
          limit: 10,
          page: 0,
          branchWarehouseId: this.idBranch,
          status: INCLUDE_STATUS,
        };
        if (valueSearch) {
          params.unitCode = valueSearch;
        }
        const findColumn = this.columnsTable.find(
          column => column.responsiveColSelect
        )?.responsiveColSelect;
        if (findColumn) findColumn[0].loading = true;
        findAllAssetRent(params)
          .then(response => {
            if (findColumn) {
              /**
                this variable to store data before map with the purpose to store data unit code in dataSource
               **/
              const tempDataOptionSelected = findColumn[0].options
                .filter(dataFilter =>
                  this.dataSource.some(
                    dataSome => dataSome.unitCode === dataFilter.id
                  )
                )
                .slice();
              findColumn[0].options = response.map(dataMap => {
                return { ...dataMap, name: dataMap.unitCode };
              });
              findColumn[0].options = [
                ...findColumn[0].options,
                ...tempDataOptionSelected,
              ]
                .filter(dataFilter => dataFilter !== undefined)
                .filter(
                  (dataFilter, index, self) =>
                    index === self.findIndex(t => t.id === dataFilter.id)
                );
            }
          })
          .finally(() => {
            if (findColumn) findColumn[0].loading = false;
          });
      } else {
        this.$notification.error({
          message: "Error",
          description: "Select Branch First to get unitCode",
        });
      }
    },
    getUnitCodeTableDetail(): Promise<AssetResponseDto[]> {
      const { findAllAssetRent } = useAsset();
      const INCLUDE_STATUS = "RENTED,READY,NEED_TO_REPAIR";
      const params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
        branchWarehouseId: this.idBranch,
        status: INCLUDE_STATUS,
      };
      return findAllAssetRent(params);
    },
    onRangePickerChange(dates: Array<any>): void {
      if (!dates.length) {
        this.modelForm.date = undefined;
      }
    },
    handleBack() {
      this.$confirm({
        title: this.$t("lbl_leave_page"),
        onOk: () => {
          this.$router.push("/sales/internal-contract/list");
        },
        onCancel() {
          return;
        },
      });
    },
    handleCancel() {
      if (this.detail.status === EnumStatus.New) {
        this.modelForm.date = [];
        this.dataSource = [];
        this.footerCreateInternalContract.total = 0;
        this.form.resetFields();
      } else if (this.detail.isCanCancel) {
        this.$confirm({
          title: `Are you sure want to cancel?`,
          onOk: () => {
            const icNumber = this.form.getFieldsValue(["internalContractNo"]);
            salesOrderServices
              .cancelInternalContract(this.idInternalContract)
              .then(() => {
                this.$notification.success({
                  description: `Internal Contract Number: ${icNumber.internalContractNo} Success Cancelled`,
                  message: "Success",
                  duration: 30,
                });
                this.$router.push("/sales/internal-contract/list");
              });
          },
          onCancel() {
            return;
          },
        });
      }
    },
    handleAddRow() {
      this.dataSource = [
        ...this.dataSource,
        {
          key: this.dataSource.length,
          unitCode: "",
          unitCodeBackup: false,
          unitParentId: null,
          unitCodeBackupNo: "",
          equipment: "",
          merk: "",
          type: "",
          qty: "1",
          uom: "",
          rentPrice: "0",
          subTotal: "",
          endContract: false,
          serialNumber: "",
          specification: "",
          location: this.defaultLocationShipTo,
          shipTo: this.defaultShipTo,
          id: "",
          optionCustom: [
            {
              name: "unitParentId",
              option: this.tempColumnOption,
            },
          ],
        },
      ];
    },
    disabledDate(current) {
      return current > moment().endOf("day");
    },
    handleSelectType(value: string): void {
      this.form.setFieldsValue({
        adj: value,
      });
    },
    handleCheckString(value): string {
      if (value) return value;
      else return "";
    },
    assignSearch(key, value, and): string {
      if (key === "no" && value) return and + `documentNumber~${value}`;
      else if (key === "location" && value)
        return and + `warehouseLocation.secureId~${value}`;
      else if (key === "adj" && value)
        return and + `type~${value.replace(" ", "_").toUpperCase()}`;
      else return "";
    },
    dynamicSearch(res): string {
      let search = "";
      Object.keys(res).forEach(key => {
        if (!search) {
          search = this.assignSearch(key, res[key], "");
        } else {
          search += this.assignSearch(key, res[key], "_AND_");
        }
      });
      return search;
    },
    Allvalidation(): boolean {
      // true jika ada error
      let tempError: string[] = [];
      if (this.services) {
        if (this.dataSourceProductServices.length === 0)
          tempError.push("Tab Product at least have 1 data");
      } else {
        if (this.dataSource.length === 0)
          tempError.push("Tab Unit Code at least have 1 data");
      }

      this.dataSourceProductServices.forEach(dataForeach => {
        if (
          !dataForeach.productId ||
          !dataForeach.productName ||
          !dataForeach.productCode
        ) {
          tempError.push("Product can't empty");
        }
        if (changeCurrencytoNumeric(dataForeach.qty) < 1) {
          tempError.push("Qty cannot be less than equal to 0");
        }
        if (changeCurrencytoNumeric(dataForeach.rentPrice) < 1) {
          tempError.push("Rent Price cannot be less than equal to 0");
        }
      });

      const checkDataSource = this.dataSource.some(
        dataSome => !dataSome.shipTo
      );
      const checkChild = this.dataSource.some(
        dataSome => !dataSome.shipTo && !dataSome.unitParentId
      );
      if (checkChild) {
        tempError.push(
          "Ship to & parent (unit child) in List of Internal Contract can't empty"
        );
      } else if (checkDataSource) {
        tempError.push("Ship to in List of Internal Contract can't empty");
      }

      this.dataSource.forEach(dataForeach => {
        if (changeCurrencytoNumeric(dataForeach.qty) < 1) {
          tempError.push("Qty cannot be less than equal to 0");
        }
        // child boleh 0 makanya checkChild harus false dlu
        if (
          !checkChild &&
          !dataForeach.unitParentId &&
          !dataForeach.unitCodeBackup &&
          changeCurrencytoNumeric(dataForeach.rentPrice) < 1 &&
          !dataForeach.endContract
        ) {
          tempError.push("Rent Price cannot be less than equal to 0");
        }
      });

      if (tempError.length > 0) {
        tempError = [...new Set(tempError)];
        tempError.forEach(dataForeach => {
          debounce(() => {
            this.$notification.error({
              message: "Error",
              description: dataForeach,
            });
          }, 20);
        });
        return true;
      } else {
        return false;
      }
    },
    async onSubmit(
      type,
      additionStatus?: "Approved" | "Rejected" | "Extended" | "Ended"
    ): Promise<void> {
      try {
        if (type === "Update") {
          this.view = false;
          this.detail.status = EnumStatus.Revised;
        } else if (type === "History") {
          const params: RequestQueryParamsModel = {
            limit: 1000,
            page: 0,
            search: `internalContract.secureId~${this.idInternalContract}`,
            sorts: "createdDate:desc",
          };
          this.modalHistoryOpen = true;
          salesOrderServices
            .getDetailHistoryInternalContract(params)
            .then(response => {
              this.dataHistoryTimeLine = response.data;
            });
        } else if (!this.Allvalidation()) {
          this.form.validateFields((err, res) => {
            if (err) return;
            let countEndContract = 0;
            let sumSubTotal = 0;
            const payload: RequestInternalContract = {
              periodType: this.rentType,
              branchId: this.handleCheckString(res.branchName),
              referenceNo: this.handleCheckString(res.referenceNo),
              contractDate: res.internalContractDate
                ? moment(res.internalContractDate).format("yyyy-MM-DD")
                : "",
              customerId: this.handleCheckString(res.customerName),
              shipTo: this.handleCheckString(res.shipTo),
              billTo: this.handleCheckString(res.billTo),
              picName: this.handleCheckString(res.picName),
              currencyId: this.handleCheckString(res.currency),
              rentPeriod: res.rentPeriod,
              startRent: moment(res.rentStartingDate).format("yyyy-MM-DD"),
              // endRent: moment(res.rentPeriodRange?.split(' - ')[1]).format('yyyy-MM-DD'),
              endRent: moment(
                res.rentPeriodRange?.split(" - ")[1],
                "DD-MMM-yyyy"
              ).format("yyyy-MM-DD"),
              // endRent: res.rentPeriodRange?.split(' - ')[1],
              billingType: this.handleCheckString(res.billingType),
              termOfCondition: this.handleCheckString(res.termOfCondition),
              fileContract: this.urlForSend,
              salesId: this.handleCheckString(res.sales),
              service: this.services,
              status: type, // Draft / new / Need Approval / approved / rejected / revised / ended
              approverId: "",
              notes: res.notes,
              deleteLineId: this.deleteLineId.concat(
                this.deleteLineIdProductServices
              ),
              total:
                changeCurrencytoNumeric(
                  this.footerCreateInternalContract.total
                ) + changeCurrencytoNumeric(this.footerTotalProductServices), // sub total di sum,
              productServices: this.dataSourceProductServices.map(dataMap => {
                return {
                  productServiceId: dataMap.id,
                  productId: dataMap.productId,
                  type: dataMap.type,
                  rentPrice: changeCurrencytoNumeric(dataMap.rentPrice),
                  subTotal: changeCurrencytoNumeric(dataMap.subTotal),
                  qty: changeCurrencytoNumeric(dataMap.qty),
                  uom: dataMap.uom,
                };
              }),
              contractDetails: this.dataSource
                .map(dataMap => {
                  return {
                    unitParentId: dataMap.unitParentId,
                    unitId: dataMap.unitCode,
                    unitBackup: dataMap.unitCodeBackup,
                    equipment: dataMap.equipment,
                    merk: dataMap.merk,
                    type: dataMap.type,
                    qty: changeCurrencytoNumeric(dataMap.qty),
                    uom: dataMap.uom,
                    rentPrice: changeCurrencytoNumeric(dataMap.rentPrice),
                    subTotal: changeCurrencytoNumeric(dataMap.subTotal),
                    endContract: dataMap.endContract ? true : false,
                    id: dataMap.id,
                    location: dataMap.location,
                    shipTo: dataMap.shipTo,
                  };
                })
                .filter(
                  dataFilter =>
                    dataFilter.unitId ||
                    dataFilter.unitBackup ||
                    dataFilter.equipment ||
                    dataFilter.merk ||
                    dataFilter.type ||
                    dataFilter.qty ||
                    dataFilter.uom ||
                    dataFilter.rentPrice
                ),
            };
            switch (type) {
              case "Draft":
                this.loading.table = true;
                payload.endRent = moment(payload.endRent)
                  .set({ hour: 8, minute: 0, second: 0 })
                  .utcOffset("+07")
                  .format();
                salesOrderServices
                  .postInternalContract(payload)
                  .then(response => {
                    if (response)
                      this.$notification.success({
                        description: `Internal Contract Number: ${response}`,
                        message: "Success",
                        duration: 30,
                      });
                    else
                      this.$notification.success({
                        description: "Success save as draft",
                        message: "Success",
                        duration: 30,
                      });
                    this.$router.push("/sales/internal-contract/list");
                  })
                  .finally(() => (this.loading.table = false));
                break;
              case "Need Approval":
                this.loading.table = true;
                payload.endRent = moment(payload.endRent)
                  .set({ hour: 8, minute: 0, second: 0 })
                  .utcOffset("+07")
                  .format();
                if (this.isDraft) {
                  salesOrderServices
                    .putInternalContract(payload, this.idInternalContract)
                    .then(response => {
                      this.$notification.success({
                        description: `Internal Contract Number: ${response}`,
                        message: "Success",
                        duration: 30,
                      });
                      this.$router.push("/sales/internal-contract/list");
                    })
                    .finally(() => (this.loading.table = false));
                } else if (this.isNew) {
                  salesOrderServices
                    .postInternalContract(payload)
                    .then(response => {
                      this.$notification.success({
                        description: `Internal Contract Number: ${response}`,
                        message: "Success",
                        duration: 30,
                      });
                      this.$router.push("/sales/internal-contract/list");
                    })
                    .finally(() => (this.loading.table = false));
                }
                break;
              case "Change Unit":
                if (this.detail.status === EnumStatus.ChangeUnit) {
                  salesOrderServices
                    .putInternalContract(payload, this.idInternalContract)
                    .then(response => {
                      this.$notification.success({
                        description: `Internal Contract Number: ${response}`,
                        message: "Success",
                        duration: 30,
                      });
                      this.$router.push("/sales/internal-contract/list");
                    })
                    .finally(() => (this.loading.table = false));
                } else {
                  this.detail.status = EnumStatus.ChangeUnit;
                  this.dataSource = this.dataSource.map(dataMap => {
                    return {
                      ...dataMap,
                      disabledSelect: dataMap.endContract ? true : false,
                    };
                  });
                }
                break;
              case "Change Location":
                if (this.detail.status === EnumStatus.ChangeLocation) {
                  salesOrderServices
                    .putInternalContract(payload, this.idInternalContract)
                    .then(response => {
                      this.$notification.success({
                        description: `Internal Contract Number: ${response}`,
                        message: "Success",
                        duration: 30,
                      });
                      this.$router.push("/sales/internal-contract/list");
                    })
                    .finally(() => (this.loading.table = false));
                } else {
                  this.detail.status = EnumStatus.ChangeLocation;
                  this.dataSource = this.dataSource.map(dataMap => {
                    if (dataMap.unitParentId) {
                      return {
                        ...dataMap,
                        disableByRow: ["shipTo", "unitCode", "unitParentId"],
                        disabledSelect: false,
                      };
                    } else {
                      return {
                        ...dataMap,
                        disabledSelect: false,
                        disableByRow: ["unitCode", "unitParentId"],
                      };
                    }
                  });
                  // ini oii
                }
                break;
              case "approve/rejected":
                if (!additionStatus) {
                  this.modalOpen = true;
                  this.modeModal = "approve/rejected";
                } else {
                  this.formModal.validateFields((errModal, responseModal) => {
                    if (errModal) return;
                    payload.endRent = moment(payload.endRent)
                      .set({ hour: 8, minute: 0, second: 0 })
                      .utcOffset("+07")
                      .format();
                    payload.status = additionStatus;
                    payload.notes = responseModal.notes;
                    this.loading.table = true;
                    this.loading.submitFormModal = true;
                    salesOrderServices
                      .putInternalContract(payload, this.idInternalContract)
                      .then(response => {
                        this.$notification.success({
                          description: `Internal Contract Number: ${response}`,
                          message: "Success",
                          duration: 30,
                        });
                        this.$router.push("/sales/internal-contract/list");
                      })
                      .finally(() => {
                        this.loading.table = false;
                        this.loading.submitFormModal = false;
                      });
                  });
                }
                break;
              case "Extend":
                if (!additionStatus) {
                  this.modalOpen = true;
                  this.modeModal = "Extend";
                } else {
                  this.formModal.validateFields((errorModal, responseModal) => {
                    if (errorModal) return;
                    payload.status = additionStatus;
                    payload.notes = responseModal.notes;
                    payload.rentPeriod += responseModal.rentPeriod;
                    if (this.rentType === "Month") {
                      payload.endRent = moment(
                        payload.endRent,
                        "yyyy-MM-DD"
                      ).format("yyyy-MM-DD");
                    }
                    if (this.rentType === "Day") {
                      payload.endRent = moment(
                        payload.endRent,
                        "yyyy-MM-DD"
                      ).format("yyyy-MM-DD");
                    }

                    const sumSubTotalTable = this.dataSource.reduce((a, b) => {
                      return a + changeCurrencytoNumeric(b.subTotal);
                    }, 0);
                    const dataProductService =
                      this.dataSourceProductServices.length > 0
                        ? this.dataSourceProductServices
                        : this.detailDataSourceProductServices;
                    const sumSubTotalProductService = dataProductService.reduce(
                      (a, b) => {
                        return a + changeCurrencytoNumeric(b.subTotal);
                      },
                      0
                    );

                    payload.total =
                      sumSubTotalTable + sumSubTotalProductService;
                    payload.endRent = moment(payload.endRent)
                      .set({ hour: 8, minute: 0, second: 0 })
                      .utcOffset("+07")
                      .format();
                    this.loading.table = true;
                    this.loading.submitFormModal = true;
                    salesOrderServices
                      .putInternalContract(payload, this.idInternalContract)
                      .then(response => {
                        this.$notification.success({
                          description: `Internal Contract Number: ${response}`,
                          message: "Success",
                          duration: 30,
                        });
                        this.$router.push("/sales/internal-contract/list");
                      })
                      .finally(() => {
                        this.loading.table = false;
                        this.loading.submitFormModal = false;
                      });
                  });
                }
                break;
              case "Revised":
                this.loading.table = true;
                payload.endRent = moment(payload.endRent)
                  .set({ hour: 8, minute: 0, second: 0 })
                  .utcOffset("+07")
                  .format();
                salesOrderServices
                  .putInternalContract(payload, this.idInternalContract)
                  .then(response => {
                    this.$notification.success({
                      description: `Internal Contract Number: ${response}`,
                      message: "Success",
                      duration: 30,
                    });
                    this.$router.push("/sales/internal-contract/list");
                  })
                  .finally(() => (this.loading.table = false));
                break;
              case "Ended":
                if (!additionStatus) {
                  this.modalOpen = true;
                  this.modeModal = "Ended";
                } else {
                  this.formModal.validateFields((errModal, responseModal) => {
                    if (errModal) return;
                    payload.notes = responseModal.notes;
                    if (this.selectedRowKeys.length > 0) {
                      payload.contractDetails = payload.contractDetails.map(
                        (dataMap, index) => {
                          if (dataMap.endContract) countEndContract++;
                          if (this.selectedRowKeys.includes(index)) {
                            countEndContract++;
                            return {
                              ...dataMap,
                              endContract: true,
                              qty: 0,
                              rentPrice: 0,
                              subTotal: 0,
                            };
                          } else {
                            return {
                              ...dataMap,
                              endContract: false,
                            };
                          }
                        }
                      );

                      if (countEndContract === payload.contractDetails.length)
                        payload.status = "Ended";
                      if (countEndContract < payload.contractDetails.length)
                        payload.status = "Partial Ended";

                      payload.contractDetails.forEach(dataForeach => {
                        sumSubTotal += dataForeach.subTotal;
                      });

                      payload.total = sumSubTotal;
                      payload.endRent = moment(payload.endRent)
                        .set({ hour: 8, minute: 0, second: 0 })
                        .utcOffset("+07")
                        .format();
                      salesOrderServices
                        .putInternalContract(payload, this.idInternalContract)
                        .then(response => {
                          this.$notification.success({
                            description: `Internal Contract Number: ${response}`,
                            message: "Success",
                            duration: 30,
                          });
                          this.$router.push("/sales/internal-contract/list");
                        })
                        .finally(() => (this.loading.table = false));
                    } else {
                      this.$notification.error({
                        message: "Error",
                        description: "Select data first to end contract",
                      });
                    }
                  });
                }
                break;
              default:
                break;
            }
          });
        }
      } catch (error) {
        this.$notification.error({
          description: Messages.CREATE_FAIL,
          message: "Error",
          duration: 30,
        });
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
      };
    },
    // status can change
    isNew(): boolean {
      return this.detail.status === EnumStatus.New;
    },
    isDraft(): boolean {
      return this.detail.status === EnumStatus.Draft;
    },
    isNeedApproval(): boolean {
      return this.detail.status === EnumStatus.NeedApproval;
    },
    isRejected(): boolean {
      return this.detail.status === EnumStatus.Rejected;
    },
    isRevised(): boolean {
      return this.detail.status === EnumStatus.Revised;
    },
    isEnded(): boolean {
      return this.detail.status === EnumStatus.Ended;
    },
    isApproved(): boolean {
      return this.detail.status === EnumStatus.Approved;
    },
    isPartialEnded(): boolean {
      return this.detail.status === EnumStatus.PartialEnded;
    },
    isChangeUnit(): boolean {
      return this.detail.status === EnumStatus.ChangeUnit;
    },
    isChangeLocation(): boolean {
      return this.detail.status === EnumStatus.ChangeLocation;
    },
    isAcceptanceOfOrder(): boolean {
      return this.detail.isAcceptanceOfOrder;
    },
    isSo(): boolean {
      return this.detail.isSo;
    },
    isCancelled(): boolean {
      return this.detail.status === EnumStatus.Cancelled;
    },
    // status can't change actual in document
    isActualNew(): boolean {
      return this.detail.actualStatusDocument === EnumStatus.New;
    },
    isActualDraft(): boolean {
      return this.detail.actualStatusDocument === EnumStatus.Draft;
    },
    isActualNeedApproval(): boolean {
      return this.detail.actualStatusDocument === EnumStatus.NeedApproval;
    },
  },
  created() {
    this.mode = this.$route.meta.mode;
    this.idInternalContract = this.$route.params.id;
    this.getListCustomer("");
    this.getCurrency("");
    this.getListBillingType("");
    this.getListSales("");
    this.getListRevised("");
    this.getListBranch("");
    this.getListStatus("");
  },
  mounted() {
    switch (this.mode) {
      case Mode.CREATE:
        this.detail.status = EnumStatus.New;
        this.setDataForm({
          billingType: "Behind",
        });
        break;
      case Mode.VIEW:
        this.getDataDetail();
        break;
      default:
        break;
    }
  },
});
